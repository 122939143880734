import { createSlice } from "@reduxjs/toolkit";
import { userState } from "../states/userState";

// Create Product Slice
const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    add: (state, action) => {
      return state.map((item) => {
        // Find the item
        if (item.id !== action.payload.id) {
          return item;
        }

        // Add it to the cart
        return {
          ...item,
          added: true,
          quantity: item.quantity + 1,
        };
      });
    },
    remove: (state, action) => {
      return state.map((item) => {
        // Find the item
        if (item.id !== action.payload.id) {
          return item;
        }

        // Remove it from the cart
        return {
          ...item,
          added: false,
          quantity: item.quantity * 0,
        };
      });
    },
    loginReducer: (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_KEY,
        action.payload?.key || undefined
      );
      return {
        ...state,
        token: action.payload?.key,
        login:
          action.payload?.key && action.payload?.key !== undefined
            ? true
            : false,
      };
    },
    setPaymentStatus: (state, action) => {
      return {
        ...state,
        credits:action.payload?.credits_quantity,
        subscribed:action.payload?.has_subscribed,
        expired: action.payload?.expired === true || action.payload?.expired === false ? action.payload?.expired : null
      };
    },
    
    signupReducer: (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_KEY,
        action.payload?.key
      );
      return {
        token: action.payload?.key,
        login: true,
      };
    },
    logout: (state) => {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      return {
        ...state,
        token: null,
        login: false,
      };
    },
    hideLoader: (state) => {
      return {
        ...state,
        loader: false,
      };
    },
    showLoader: (state) => {
      return {
        ...state,
        loader: true,
      };
    },
  },
});

export default userSlice;
