import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";

const PatternAnalysisItem = ({ data, column, title, section, index, showNotes=false }) => {
    const [patternData, setPatternData] = useState([])
    const [active, setActive] = useState(null);
    useEffect(() => {
        const itemData = chunk(data, 6) || []
        setPatternData(itemData)
        itemData?.map((cData, i) => {
            cData.map((m, j) => {
                if (m.bold) {
                    setActive(index)
                }
            })
        })
    }, [data])
    const statuses = {
        Normal: "N",
        Increased: "↑",
        Decreased: "↓"
    };
    function chunk(items, size) {
        if (items && size) {
            const chunks = [];
            items = [].concat(...items);

            while (items.length) {
                chunks.push(items.splice(0, size));
            }

            return chunks;
        }
    }
    return (
        <Col lg={column} className="mx-auto pattern-analysis-accordion">
            <Accordion className={`${section}`}
                key={index}
                activeKey={active}
                onSelect={(e) => setActive(parseInt(active) >= 0 ? null : index)}
            >
                <Accordion.Item eventKey={index}>
                    <Accordion.Header>{title} </Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            {patternData?.map((cData, i) => {
                                return (
                                    <Col className="px-0" key={i}>
                                        <ul className="px-0">
                                            {cData.map((m, j) => {
                                                return (
                                                    <li key={j} dangerouslySetInnerHTML={{ __html: m.status }}>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Col>
                                );
                            })}
                            {showNotes ? 
                            <div className="mt-3 text-center fw-bold font-12">
                            Note that some markers in this pattern may appear as bold while they are within Normal values. Use your discretion to determine if these markers are clinically relevant and if the pattern is indeed present, or if the Normal finding is just that - normal.
                            </div>
                            : null}   
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Col>

    )
}
export default PatternAnalysisItem