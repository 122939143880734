import React from "react";
import { Container } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import AccountSettings from "../AccountSettings/AccountSettings";
import AddNewClient from "../AddNewClient";
import AddNewClient1 from "../AddNewClient/AddNewClient1";
import AddNewClient3 from "../AddNewClient/AddNewClient3";
import ClientDashboard from "../ClientDashboard/ClientDashboard";
import ClientSearchSceen from "../ClientSearchModule/ClientSearchScreen";
import HeaderButtons from "../Shared/HeaderButtons";
import Sidebar from "./Sidebar";
import { Tests } from "../Tests/Tests";
import "./style.css";
import ComparisonReview from "../ClientSearchModule/ComparisonReview";
import UseLocationHook from "../../hooks/useLocationHook";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store";
const HomePageRouter = () => {
  const userData = useSelector(selectUserData)
  const paymentStatus = userData?.credits === 0 && userData?.expired === true && !userData?.subscribed
  return (
    <Router>
      <Container fluid className="main-container">
        {paymentStatus && <div class="notification w-100 text-center position-fixed top-0 text-white bg-primary py-1 fw-bold">Your subscription has expired. You can still access your previous test reports, but running new reports requires an active subscription. <br/><Link className="text-white" to={"/client"}><u className="fw-bold cursor-pointer">Renew Subscription.</u></Link></div>}
        <div className={`mainDiv d-flex ${paymentStatus ? 'mt-5':''}`}>
          <div className="sidebar-section">
            <Sidebar />
          </div>
          <div className="mainInner w-100">
            <HeaderButtons />
            <UseLocationHook/>
            <Routes>
              <Route path="/client" element={<AddNewClient />} />
              <Route
                path="/clientscreensearch"
                element={<ClientSearchSceen />}
              />
              <Route path="/accountsettings" element={<AccountSettings />} />
              <Route
                path="/client-report/:id/:name"
                element={<ClientDashboard />}
              />
              <Route
                path="/report-update/:id/"
                element={<AddNewClient1 />}
              />
              <Route path="/*" element={<Navigate to={"/client"} />} />
              <Route path="/tests" element={<Tests />} />
              <Route path="/comparison-review/:name/" element={<ComparisonReview />} />
            </Routes>
          </div>
        </div>
      </Container>
    </Router>
  );
};

export default HomePageRouter;
